<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
        >
          <b-card-body>
            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- Habit name -->
                  <b-col md="12">
                    <b-form-group
                      :label="$t('NAME')"
                      label-for="id-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('NAME')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-name"
                          v-model="task.taskbeskrivning"
                          maxlength="100"
                          autofocus
                          :placeholder="$t('NAME')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Points -->
                  <b-col md="6">

                    <b-form-group
                      :label="$t('POINTS')"
                      label-for="id-task-points"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('POINTS')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-task-points"
                          v-model="task.points"
                          maxlength="3"
                          type="number"
                          trim
                          :placeholder="$t('POINTS')"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>

                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!--Sort order -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('SORT_ORDER')"
                      label-for="id-sortOrder"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('SORT_ORDER')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-sortOrder"
                          v-model="task.sortOrder"
                          maxlength="3"
                          type="number"
                          :placeholder="$t('SORT_ORDER')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Description -->
                  <b-col md="12">
                    <b-form-group
                      :label="$t('DESCRIPTION')"
                      label-for="id-description"
                    >
                      <quill-editor
                        id="id-description"
                        v-model="task.taskdescription"
                        maxlength="100"
                        :options="editorOption"
                        class="border-bottom-0"
                      />
                      <div
                        id="quill-toolbar"
                        class="d-flex justify-content-end border-top-0"
                      >
                        <!-- Add a bold button -->
                        <button class="ql-bold" />
                        <button class="ql-italic" />
                        <button class="ql-underline" />
                        <button class="ql-align" />
                        <button class="ql-link" />
                      </div>
                    </b-form-group>
                  </b-col>
                  <!--Start value-->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('START_VALUE')"
                      label-for="id-startvalue"
                    >
                      <b-form-input
                        id="id-startvalue"
                        v-model="task.startValue"
                        type="number"
                        :placeholder="$t('START_VALUE')"
                      />
                    </b-form-group>
                  </b-col>
                  <!--End value -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('END_VALUE')"
                      label-for="id-endvalue"
                    >
                      <b-form-input
                        id="id-endvalue"
                        v-model="task.endValue"
                        maxlength="3"
                        type="number"
                        :placeholder="$t('END_VALUE')"
                      />
                    </b-form-group>
                  </b-col>
                  <!--Comment prompt -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('COMMENT')"
                      label-for="id-commentprompt"
                    >
                      <b-form-input
                        id="id-commentprompt"
                        v-model="task.commentprompt"
                        maxlength="80"
                        :placeholder="$t('COMMENT')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- frequency -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('FREQUENCY')"
                      label-for="id-frequencyType"
                    >
                      <v-select
                        id="id-frequencyType"
                        v-model="task.freq"
                        :placeholder="$t('SELECT_FREQUENCY')"
                        label="name"
                        :clearable="false"
                        class="flex-grow-1"
                        :options="frequencyType"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- post video -->
                  <b-col
                    v-show="media.newmediapath"
                    md="12"
                    cols="12"
                    class="mb-2 mt-2"
                  >
                    <b-embed
                      type="iframe"
                      :src="media.newmediapath"
                      allowfullscreen
                      class="rounded mb-50"
                    />
                    <div class="icon-with-text mt-2">
                      <span class="text  mr-1">{{ $t('MEDIA') }} : {{ media.header }} ({{ media.mediaID }})</span>
                    </div>
                  </b-col>
                  <!-- submit and reset -->
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ task.taskID ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      {{ $t('BUTTON.RESET') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton,
  BForm, BFormGroup, BFormInput, BEmbed,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { quillEditor } from 'vue-quill-editor'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import router from '@/router'
import mixinAlert from '@/constants/mixinAlert'
import useJwt from '@/auth/jwt/useJwt'
import constants from '@/constants/static.json'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BEmbed,
    vSelect,
  },
  data() {
    return {
      competition: useJwt.getContent(),
      task: {
        taskID: 0,
        subTask: [],
        taskdescription: '',
        taskbeskrivning: '',
        points: '',
        taskhelp: '',
        startValue: null,
        endValue: null,
        requireComment: 0,
        requireMultiplier: 0,
        commentprompt: '',
        charity_weight: 0,
        question: null,
        competitionID: 0,
        sortOrder: 0,
        freq: '',
      },
      media: {
        mediaID: 0,
        header: '',
        url: '',
        newmediapath: '',
      },
      frequencyType: constants.FREQUENCY_TYPE,
      required,
    }
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your habit description',
    }

    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
      editorOption,
    }
  },
  created() {
    if (router.currentRoute.params.id) {
      this.fetchActivityByID(router.currentRoute.params.id).then(response => {
        if (response) {
          this.task = this.getActivity.task
          this.task.sortOrder = this.getActivity.sortOrder
          if (this.task.mediaID) this.fetchMedia(this.task.mediaID)
        }
      })
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters('activity', ['getActivity']),
    ...mapGetters('media', ['getMedia']),
  },
  methods: {
    ...mapActions('activity', ['createActivity', 'updateActivity', 'fetchActivityByID']),
    ...mapActions('media', ['fetchMediaByID']),
    fetchMedia(id) {
      this.fetchMediaByID(id).then(response => {
        if (response) {
          this.media = this.getMedia
        }
      })
    },
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          const payload = { ...this.task }
          payload.competitionID = this.competition.ID
          if (this.task.taskID) {
            this.updateActivity({ task: payload }).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.HABIT_UPDATED'))
                this.$router.push({ name: 'activity-list' })
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          } else {
            this.createActivity({ task: payload }).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.HABIT_CREATED'))
                this.$router.push({ name: 'activity-list' })
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        }
      })
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/quill.scss';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

</style>
